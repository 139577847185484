<template>
  <div class="address">
    <center-title title="收货地址"></center-title>
    <router-link to="/editaddress?type=add" class="add row-center xs mb20"
      >+ 新增收货地址</router-link
    >
    <div class="table">
      <div class="table-item th row lighter">
        <div class="item">收货人</div>
        <div class="item">收货地址</div>
        <div class="item">邮编</div>
        <div class="item">电话/手机</div>
        <div class="item">操作</div>
      </div>
      <div
        class="table-item row xs"
        v-for="(item, index) in addressList"
        :key="index"
      >
        <div class="item">{{ item.contact }}</div>
        <div class="item">
          <!-- {{山东省 淄博市 张店区}} -->
          {{ item.province }}&nbsp;&nbsp;{{ item.city }}&nbsp;&nbsp;{{
            item.district
          }}
          <br />
          {{ item.address }}
          <!-- 城区花园小区20号楼2单元101 -->
        </div>
        <div class="item">{{item.post_code}}</div>
        <div class="item">{{ item.telephone }}</div>
        <div class="item status-list">
          <router-link
            :to="`/editaddress?type=edit&id=${item.id}`"
            class="status-item"
            >修改</router-link
          >
          <div class="status-item" @click="delAddressDetail(item.id)">删除</div>
          <div
            class="status-item size"
            @click="setDefault(item)"
            :class="{ active: item.is_default }"
          >
            {{ item.is_default ? "默认地址" : "设为默认" }}
          </div>
        </div>
      </div>
      <div class="table-item row xs" v-if="!addressList.length">
        <div class="item flex1">暂无收货地址</div>
      </div>
    </div>
  </div>
</template>
<script>
import CenterTitle from "../components/CenterTitle";
export default {
  name: "",
  props: {},
  components: {
    "center-title": CenterTitle,
  },
  data() {
    return {
      addressList: [],
    };
  },
  computed: {},
  watch: {},
  methods: {
    // 获取地址列表
    getAddressList() {
      this.$api.getAddressListApi().then((res) => {
        this.addressList = res.data;
      });
    },
    // 设置默认地址
    setDefault(item) {
      if (item.is_default) return;
      this.$api
        .setDefaultApi({
          id: item.id,
        })
        .then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getAddressList();
        });
    },
    // 删除地址
    delAddressDetail(id) {
      this.$api
        .delAddressDetailApi({
          id,
        })
        .then((res) => {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.getAddressList();
        });
    },
  },
  created() {
    this.getAddressList();
  },
  mounted() {},
  beforeDestroy() {},
};
</script>
<style lang='scss' scoped>
.add {
  border: $-solid-border;
  height: 40px;
  line-height: 40px;
  cursor: pointer;
}
.table {
  border-top: $-solid-border;
  border-left: $-solid-border;
  .table-item {
    align-items: inherit;
    &.th {
      background-color: $-color-center;
    }
    .item {
      min-height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 15px 0;
      line-height: 1.5;
      border-right: $-solid-border;
      border-bottom: $-solid-border;
      &.status-list {
        padding: 0 20px;
        justify-content: space-between;
        &:not(.active) {
          .status-item {
            &:hover {
              color: $-color-primary;
              cursor: pointer;
            }
          }
        }
        .status-item {
          &.size {
            padding: 7px 8px;
            &.active {
              color: $-color-primary;
              border: 1px solid $-color-primary;
              background-color: rgba($color: $-color-primary, $alpha: 0.2);
              border-radius: 4px;
            }
          }
        }
      }
      &:nth-child(1) {
        width: 15%;
      }
      &:nth-child(2) {
        width: 32%;
        text-align: left;
        padding:10px 30px 10px 50px;
        justify-content: flex-start;
      }
      &:nth-child(3) {
        width: 14%;
      }
      &:nth-child(4) {
        width: 16%;
      }
      &:nth-child(5) {
        width: 23%;
      }
    }
  }
}
</style>